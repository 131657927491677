import { useEffect, useState } from "react";
import "../Styles/Ourcars.css";
import { baseUrl } from '../config';

const OurCars = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    fetch(`${baseUrl}video-resources/1`)
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((error) => console.error(error));
  }, []);
  return (
    <>
      <div className="our-cars-container py-5">
        <div className="container-xl">
          <h2 className="display-6 text-center pb-4 ">Videos</h2>
        </div>
        <div className="our-cars-videos d-flex align-items-center justify-content-center">
          <div>
            {" "}
            <iframe
              width="100%"
              height="100%"
              src={`${data.video_url_1}`}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
          </div>
          <div className="space"></div>
          <div>
            {" "}
            <iframe
              width="100%"
              height="100%"
              src={`${data.video_url_2}`}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurCars;

import { Link } from "react-router-dom";
import "../Styles/BlogPosts.css";
import { useEffect, useState } from "react";
import { baseUrl, baseUrlForStorage } from "../config"; // Make sure you have this base URL correctly defined
import he from 'he';

const BlogPost = () => {
  const [blogPosts, setBlogPosts] = useState([]);

  useEffect(() => {
    // Fetch blog posts when the component mounts
    const fetchBlogPosts = async () => {
      try {
        const response = await fetch(`${baseUrl}blogs/recent`);
        const data = await response.json();
        const latestBlogPosts = data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)).slice(0, 3);
        setBlogPosts(latestBlogPosts);
      } catch (error) {
        console.error("Failed to fetch blog posts:", error);
      }
    };

    fetchBlogPosts();
  }, []);

  return (
    <>
      <div className="blog-post-container py-5">
        <div className="container-xl">
          <div className="blog-post">
            <h2 className="display-6 mt-3 title" data-aos="fade-right">
              Blogs 
            </h2>
            <div className="blogs d-flex justify-content-center">
              {blogPosts.map((post, index) => (
                <div
                  key={index}
                  className="blog-card mt-3 mx-2"
                  data-aos="fade-left"
                  data-aos-delay={`${100 * (index + 1)}`}
                >
                  <img src={`${baseUrlForStorage}/${post.image}`} alt={post.title} className="blog-card-image" />
                  <div className="blog-card-body">
                    <h3 className="blog-card-title">{post.title}</h3>
                    <p className="blog-card-description">
                      {post.description.split('.').slice(0, 3).join('.').concat('.')}
                    </p>
                    <Link to={`/SingleBlogPost/${post.id}`} className="blog-card-source-btn">Read More</Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogPost;

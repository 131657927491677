import google from "../assests/google.png";
import { MdOutlineStarPurple500 } from "react-icons/md";
import "../Styles/Reviews.css";

const Reviews = () => {
  return (
    <>
      <div className="container-xl">
        <div className="reviews-img text-center mt-4">
          <img src={google} alt="" />
        </div>
        <div className="reviews d-flex align-items-center justify-content-center gap-2">
          <p className="m-0 fw-bold">Reviews</p>
          <div className="stars d-flex align-items-center justify-content-center gap-1 fs-5">
            <MdOutlineStarPurple500 className="star" />
            <MdOutlineStarPurple500 className="star" />
            <MdOutlineStarPurple500 className="star" />
            <MdOutlineStarPurple500 className="star" />
            <MdOutlineStarPurple500 className="star" />
          </div>
        </div>
        <span className="text-center d-block check-reviews mt-3">
          <a
            href="https://www.google.com/search?q=barugzaimotors&oq=barugzaimotors&gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIGCAEQRRg8MgYIAhBFGDwyBggDEEUYPDIPCAQQLhgNGK8BGMcBGIAEMgkIBRAAGA0YgAQyBggGEEUYPDIGCAcQRRg80gEINzA4M2owajeoAgiwAgE&sourceid=chrome&ie=UTF-8#lrd=0x3e5f679fd4890ee3:0x9837632ab026e349,1,,,"
            className="mt-5 fw-bold"
            target="_blank"
            rel="noopener noreferrer"
          >
            View All Reviews
          </a>
        </span>
      </div>
    </>
  );
};

export default Reviews;

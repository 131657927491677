import Header from "../Comp/Header";
import HomeSlider from "../Comp/HomeSlider";
import Welcome from "../Comp/BlogDetails";
import "../Styles/Home.css";
import NewArrivals from "../Comp/NewArrivals";
import Reviews from "../Comp/Reviews";
import ProfilesComments from "../Comp/ProfilesComments";
import InstagramPost from "../Comp/InstagramPost";
import OurAchievements from "../Comp/OurAchievements";
import OurPartners from "../Comp/OurPartners";
import OurCars from "../Comp/Ourcars";
import BlogPost from "../Comp/BlogPosts";
import Footer from "../Comp/Footer";

const Home = () => {
  return (
    <>
      <div className="home">
        <Header />
        <HomeSlider />
      </div>
      <div
  style={{
    backgroundColor: 'grey',
    height: '10px',
    width: '100%',
    
    boxShadow: 'inset 0px 0px 10px rgba(0, 0, 0, 0.8)', // Inner shadow
  }}
/>
      <Welcome />
     
      <NewArrivals />
      <Reviews />
      <ProfilesComments />
      <div
  style={{
    backgroundColor: 'grey',
    height: '10px',
    width: '100%',
    marginTop: '28px', // Equivalent to mt: 7 in MUI
    boxShadow: 'inset 0px 0px 10px rgba(0, 0, 0, 0.8)', // Inner shadow
  }}
/>
      <InstagramPost />
      <OurAchievements />
      <OurPartners />
      <OurCars />
      <BlogPost />
      <Footer />
    </>
  );
};

export default Home;

import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { MdOutlineStarPurple500 } from "react-icons/md";
import "../Styles/ProfilesComments.css";
import Lottie from "react-lottie";
import loadingAnimation from "../assests/car-loading.json"; // Make sure to add a suitable loading animation file
import { baseUrl } from "../config"; // Make sure you have this base URL correctly defined

const ProfilesComments = () => {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await fetch(`${baseUrl}reviews`);

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();
        if (data.result && data.result.reviews) {
          setReviews(data.result.reviews);
        } else {
          throw new Error('No reviews found');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchReviews();
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <div className="overflow-hidden">
        <div className="profile-container pb-5">
          {loading ? (
            <div className="loader-container">
              <Lottie options={defaultOptions} height={150} width={150} />
            </div>
          ) : error ? (
            <div className="error-message">
              <p>{error}</p>
            </div>
          ) : (
            <Swiper
              slidesPerView={"auto"}
              centeredSlides={true}
              spaceBetween={30}
              className="mySwiper Profile-swiper mt-5 mb-3"
            >
              {reviews.map((review, index) => (
                <SwiperSlide key={index} className="profile-slide">
                  <div className="profile-infos d-flex align-items-center gap-3">
                    <div className="profile-logo">
                      <img
                        src={`${review.profile_photo_url}`}
                        alt={review.author_name}
                    
                      />
                    </div>
                    <div className="profile-content">
                      <h3 className="profile-name fw-bold fs-6">
                        {review.author_name}
                      </h3>
                      <div className="profile-reviews">
                        {[...Array(review.rating)].map((_, i) => (
                          <MdOutlineStarPurple500 key={i} className="fs-5" />
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="profile-desc">
                    <p>{review.text}</p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </div>
      </div>
    </>
  );
};

export default ProfilesComments;

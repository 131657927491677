import Footer from "../Comp/Footer";
import Header from "../Comp/Header";
import "../Styles/ContactUs.css";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { useEffect, useState } from "react";
import { baseUrl } from "../config";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactUs = () => {
  const [data, setData] = useState([]);
  const [companyDetails, setCompanyDetails] = useState(null);

  const [form, setForm] = useState({
    first_name: '',
    last_name: '',
    email: '',
    mobile: '',
    interestedIn: '',
    message: '',
  });


 
  
  const [errors, setErrors] = useState({});
  const [submissionMessage, setSubmissionMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm(prev => ({ ...prev, [name]: value }));
  };

  const validateForm = () => {
    let formIsValid = true;
    let newErrors = {};

    if (!form.first_name) {
      formIsValid = false;
      newErrors.first_name = "First name is required";
    }
    // Add more validation as necessary

    setErrors(newErrors);
    return formIsValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      const response = await fetch(`${baseUrl}interested-users`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(form),
      });

      if (!response.ok) {
        const errorData = await response.json();
        // Triggering error toast
        toast.error('Failed to submit form: ' + (errorData.message || "An unexpected error occurred"), {
          position: "bottom-center",
        });
        return;
      }
    
      const result = await response.json();
      console.log(result);
      setSubmissionMessage("Form submitted successfully. Thank you!");

      toast.success('Your message has been sent successfully!', {
        position: "bottom-center",
      });
      setForm({
        first_name: '',
       last_name: '',
        email: '',
        mobile: '',
        interestedIn: '',
        message: '',
      }); // Reset form
    } catch (error) {
      setSubmissionMessage(error.toString());
      toast.error('Submission error: ' + error.toString(), {
        position: "bottom-center",
      });
    }
  };
  useEffect(() => {
    fetch(`${baseUrl}first-sections/1`)
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((error) => console.error(error));
      fetch(`${baseUrl}company-details/1`)
      .then((response) => response.json())
      .then((details) => setCompanyDetails(details))
      .catch((error) => console.error("Error fetching company details:", error));
    
  }, []);
  const mapStyles = {
    height: "100vh",
    width: "100%",
  };
  const defaultCenter = {
    lat: 40.712776,
    lng: -74.005974,
  };
  return (
    <>

      <div className="Contact-us-container">
        <div className="about-us-img">
          <img
            src="https://images.unsplash.com/photo-1629820408206-e9fc918abf63?q=80&w=1386&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt=""
            className="about-us-image"
          />
          <h1 className="display-2 about-us-title text-light fw-bold ">
            Contact Us
          </h1>
          <div className="head-container">
            <div className="container-xxl pt-3">
              <Header />
            </div>
          </div>
        </div>
       
       


        <div className="container-xl">
          <form onSubmit={handleSubmit}>
            <div className="row justify-content-between mt-5">
              <div className="col-12  col-lg-6 ">
                <h2 className=" display-6 pt-4 fw-bold pb-3">Get in Touch</h2>
                <div className="contact-us-from py-5 ">
                  <div>
                    <p className="fw-light p-0 mb-1">First Name</p>
                    <input type="text" 
              className="py-2 px-2" 
              name="first_name" 
              value={form.first_name} 
              onChange={handleChange} required/>
                  </div>

                  <div>
                    <p className="fw-light p-0 mb-1">Last Name</p>
                    <input type="text" 
              className="py-2 px-2" 
              name="last_name" 
              value={form.last_name} 
              onChange={handleChange} required/>
                  </div>
                  <div>
                    <p className="fw-light p-0 mb-1">Email</p>
                    <input type="text" 
              className="py-2 px-2" 
              name="email" 
              value={form.email} 
              onChange={handleChange}  required/>
                  </div>
                  <div>
                    <p className="fw-light p-0 mb-1">Mobile</p>
                    <input type="text" 
              className="py-2 px-2" 
              name="mobile" 
              value={form.mobile} 
              onChange={handleChange} required/>
                  </div>
                  <div>
                    <p className="fw-light p-0 mb-1">
                      What car are you interested in?:
                    </p>
                    <textarea type="text" 
              className="py-2 px-2" 
              name="interestedIn" 
              value={form.interestedIn} 
              onChange={handleChange} required/>
                  </div>
                  <div>
                    <p className="fw-light p-0 mb-1">Message:</p>
                    <textarea type="text" 
              className="py-2 px-2" 
              name="message" 
              value={form.message} 
              onChange={handleChange} required/>
                  </div>
                </div>
                <button className="py-2 px-4 mt-2" type="submit">Submit</button>
              </div>
              <div className=" col-12  col-lg-5">
                <h2 className=" display-6  pt-4 fw-bold pb-3">Contact Info</h2>
                <div className="Contact-Info pt-5">
                  <h2 className="fs-5 fw-bold">LET'S TALK</h2>
                  <p className="mb-1">{companyDetails?.email}</p>
                  <p className="mb-1">{companyDetails?.phone}</p>

                  <h2 className="fs-5 fw-bold mt-5">VISIT US</h2>
                  <p className="mb-1">
                  {companyDetails?.our_location}
                  </p>

                  <h2 className="fs-5 fw-bold mt-5">OPENING HOURS</h2>
                  <p className="mb-1">{companyDetails?.our_opening_hours}</p>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div className="contact-map-container py-5">
          <LoadScript googleMapsApiKey="">
            <GoogleMap
              mapContainerStyle={mapStyles}
              zoom={13}
              center={defaultCenter}
            >
              <Marker position={defaultCenter} />
            </GoogleMap>
          </LoadScript>
          <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
        </div>
      
        <Footer />
      </div>
    </>
  );
};

export default ContactUs;

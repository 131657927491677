import { useEffect, useState } from "react";
import Header from "../Comp/Header";
import "../Styles/Services.css";
import { Link } from "react-router-dom";
import { FaLongArrowAltRight } from "react-icons/fa";
import { FaGears } from "react-icons/fa6";
import Footer from "../Comp/Footer";
import { FaCar, FaTools, FaRoad } from 'react-icons/fa';
import Modal from 'react-modal';
import { useSpring, animated } from 'react-spring';
import { baseUrl, baseUrlForStorage } from "../config";
import logo from "../assests/logo.png"; // Import your logo

const Services = () => {
  const [data, setData] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedService, setSelectedService] = useState({});
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    fetch(`${baseUrl}services`)
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        setLoading(false); // Set loading to false once data is fetched
      })
      .catch((error) => {
        console.error(error);
        setLoading(false); // Set loading to false in case of error
      });
  }, []);

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '20px',
      border: '1px solid #ccc',
      borderRadius: '4px',
      boxShadow: '0 4px 6px rgba(0,0,0,.1)',
      zIndex: 1000, // Ensure modal is above other content
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)', // Dark overlay
      zIndex: 999, // Ensure overlay is just below the modal but above other content
    },
  };

  Modal.setAppElement('#root');

  const openModal = (service) => {
    setSelectedService(service);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const animation = useSpring({
    opacity: modalIsOpen ? 1 : 0,
    transform: modalIsOpen ? `translateY(0)` : `translateY(-100px)`,
  });

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <img
            src={logo}
            alt="Loading..."
            style={{
              width: "150px",
              height: "150px",
              animation: "fadeIn 1s ease-in-out",
              objectFit: "cover",
            }}
          />
        </div>
      ) : (
        <div className="About-us-container">
          <div className="head-container">
            <div className="about-us-img">
              <img
                src="https://images.unsplash.com/photo-1636761358757-0a616eb9e17e?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt=""
                className="about-us-image"
              />
              <h1 className="display-2 about-us-title text-light fw-bold ">Services</h1>
              <div className="head-container">
                <div className="container-xxl pt-3">
                  <Header />
                </div>
              </div>
            </div>
          </div>

          <div className="container-xl">
            <div className="services-grid py-5">
              {data &&
                data.map((ele) => {
                  return (
                    <div
                      key={ele.id}
                      className="d-flex align-items-center flex-column justify-content-center py-3 px-3 service"
                    >
                      <div className="services-img-icon">
                        <img
                          src={`${baseUrlForStorage}/${ele.image}`}
                          alt=""
                        />
                      </div>
                      <h2 className="fw-bold fs-5 mt-2">{ele.title}</h2>
                      <p className="text-center text-desc">
                        {ele.description}
                      </p>
                      <button onClick={() => openModal(ele)} className="more-btn">
                        <span>More</span> <FaLongArrowAltRight />
                      </button>
                    </div>
                  );
                })}
            </div>
          </div>

          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Service Detail"
          >
            <animated.div style={animation}>
              <h2><strong>{selectedService.title}</strong></h2>
              <p><strong>{selectedService.description}</strong></p>
              <p dangerouslySetInnerHTML={{ __html: selectedService.body }}></p>
              <button onClick={closeModal} className="close-btn">Close</button>
            </animated.div>
          </Modal>

          <div className="supperlative-services py-5">
            <div className="container-xl">
              <h2 className="section-title fs-1 fw-bold">Superlative Services</h2>
              <p className="section-subtitle fs-6 mb-5">
                Discover our exceptional services tailored to elevate your automotive experience. From customization to maintenance, we ensure excellence.
              </p>
              <div className="super-services-container">
                <div className="service-item d-flex align-items-start px-3 py-3 gap-3">
                  <div className="icon-container"><FaCar className="service-icon" /></div>
                  <div className="service-description">
                    <h3 className="service-title fs-5 fw-bold">Exclusive Customization</h3>
                    <p>Personalize your ride with bespoke modifications and enhancements.</p>
                  </div>
                </div>
                <div className="service-item d-flex align-items-start px-3 py-3 gap-3">
                  <div className="icon-container"><FaTools className="service-icon" /></div>
                  <div className="service-description">
                    <h3 className="service-title fs-5 fw-bold">Professional Maintenance</h3>
                    <p>Keep your vehicle in peak condition with our expert maintenance services.</p>
                  </div>
                </div>
                <div className="service-item d-flex align-items-start px-3 py-3 gap-3">
                  <div className="icon-container"><FaRoad className="service-icon" /></div>
                  <div className="service-description">
                    <h3 className="service-title fs-5 fw-bold">Roadside Assistance</h3>
                    <p>Enjoy peace of mind with 24/7 support and roadside assistance anywhere.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      )}
    </>
  );
};

export default Services;

import { useEffect, useState } from "react";
import Header from "../Comp/Header";
import "../Styles/SellYourCar.css";
import Footer from "../Comp/Footer";
import { useSpring, animated } from "react-spring";
import { useInView } from "react-intersection-observer";
import { baseUrl } from "../config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SellYourCar = () => {
  const [data, setData] = useState([]);
  const [dataThree, setDataThree] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [htmlContent, setHtmlContent] = useState(""); // State to store the HTML content

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const fadeIn = useSpring({
    to: {
      opacity: inView ? 1 : 0,
      transform: inView ? "translateY(0)" : "translateY(50px)",
    },
    from: {
      opacity: 0,
      transform: "translateY(50px)",
    },
    config: { mass: 1, tension: 210, friction: 20 },
    delay: 300,
  });

  const [form, setForm] = useState({
    manufacturer: "",
    model: "",
    model_year: "",
    chassis_no: "",
    kilometers: "",
    engine_size: "",
    vehicle_options: "",
    expected_price: "",
    description: "",
    full_name: "",
    mobile_number: "",
    email_address: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const validateForm = () => {
    let formIsValid = true;
    let newErrors = {};

    if (!form.first_name) {
      formIsValid = false;
      newErrors.first_name = "First name is required";
    }

    return formIsValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${baseUrl}car-sales`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(form),
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error(errorData);
        toast.error("Submission failed: " + (errorData.message || "Please check the form."));
        return;
      }

      const result = await response.json();
      console.log(result);
      toast.success("Form submitted successfully!");

      setForm({
        manufacturer: "",
        model: "",
        model_year: "",
        chassis_no: "",
        kilometers: "",
        engine_size: "",
        vehicle_options: "",
        expected_price: "",
        description: "",
        full_name: "",
        mobile_number: "",
        email_address: "",
      });
    } catch (error) {
      console.error(error);
      toast.error("An unexpected error occurred.");
    }
  };

  useEffect(() => {
    fetch(`${baseUrl}manufacturers`)
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((error) => console.error(error));

    // Fetch HTML content
    fetch(`${baseUrl}html-content`)
      .then((response) => response.json())
      .then((data) => setHtmlContent(data.html_content)) // Assuming the API returns an object with a key 'html_content'
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    if (selectedOption) {
      fetch(`${baseUrl}car-models/manufacturer/${selectedOption}`)
        .then((response) => response.json())
        .then((data) => setDataThree(data))
        .catch((error) => console.error(error));
    } else {
      setDataThree([]);
    }
  }, [selectedOption]);

  const combinedChangeHandler = (event) => {
    handleChange(event);
    handleManufacturerChange(event);
  };

  const combinedModelChangeHandler = (event) => {
    handleChange(event);
    handleModelChange(event);
  };

  const handleManufacturerChange = (event) => {
    const selectedId = event.target.value;
    setSelectedOption(selectedId);
    setForm({ ...form, manufacturer: selectedId, model: "" });
  };

  const handleModelChange = (event) => {
    const selectedModel = event.target.value;
    setForm({ ...form, model: selectedModel });
  };

  return (
    <>
      <div className="Contact-us-container">
        <div className="sell-your-car">
          <img
            src="https://images.unsplash.com/photo-1629820408206-e9fc918abf63?q=80&w=1386&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt=""
            className="sell-your-car-image"
          />
          <h1 className="display-2 about-us-title text-light fw-bold ">
            Sell Your Car
          </h1>
          <div className="head-container">
            <div className="container-xxl pt-3">
              <Header />
            </div>
          </div>
        </div>

        <div className="sell-your-car-inputs-container py-5">
          <div className="container-xl">
            <form onSubmit={handleSubmit}>
              <div className="inputs-container py-5">
                <div className="sell-car-inputs">
                  <p className="fw-bold">Manufacturer *</p>
                  <input
                    className="py-2 px-2"
                    type="text"
                    name="manufacturer"
                    value={form.manufacturer}
                    onChange={combinedChangeHandler}
                    placeholder="Please enter Manufacturer"
                    required
                  />
                </div>
                <div className="sell-car-inputs">
                  <p className="fw-bold">Model *</p>
                  <input
                    type="text"
                    value={form.model}
                    onChange={combinedModelChangeHandler}
                    className="py-2 px-2"
                    name="model"
                    placeholder="Please enter Model or Year"
                    required
                  />
                </div>
                <div className="sell-car-inputs">
                  <p className="fw-bold">Model Year *</p>
                  <input
                    type="number"
                    name="model_year"
                    value={form.model_year}
                    onChange={handleChange}
                    className="py-2 px-2"
                    placeholder="Enter Model Year"
                    required
                    min="1990"
                    max="2024"
                  />
                </div>
                <div className="sell-car-inputs">
                  <p className="fw-bold">Chassis No*</p>
                  <input
                    placeholder="ex : A3"
                    className="py-2 px-2"
                    name="chassis_no"
                    value={form.chassis_no}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="sell-car-inputs">
                  <p className="fw-bold">Kilometers *</p>
                  <input
                    name="kilometers"
                    placeholder="ex : 150000"
                    className="py-2 px-2"
                    type="number"
                    value={form.kilometers}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="sell-car-inputs">
                  <p className="fw-bold">Engine Size *</p>
                  <input
                    placeholder="ex: 1.8"
                    className="py-2 px-2"
                    type="text"
                    name="engine_size"
                    value={form.engine_size}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="sell-car-inputs">
                  <p className="fw-bold">Vehicle Options *</p>
                  <input
                    placeholder="ex: S Line"
                    className="py-2 px-2"
                    type="text"
                    name="vehicle_options"
                    value={form.vehicle_options}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="sell-car-inputs">
                  <p className="fw-bold">Expected Price *</p>
                  <input
                    placeholder="ex: AED 50.000"
                    className="py-2 px-2"
                    type="number"
                    name="expected_price"
                    value={form.expected_price}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="sell-car-inputs">
                  <p className="fw-bold">Description *</p>
                  <textarea
                    placeholder="ex : Full Service History"
                    className="py-1 px-2"
                    type="text"
                    name="description"
                    value={form.description}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="sell-car-inputs">
                  <p className="fw-bold">Full Name *</p>
                  <input
                    placeholder="John Smith"
                    className="py-2 px-2"
                    type="text"
                    name="full_name"
                    value={form.full_name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="sell-car-inputs">
                  <p className="fw-bold">Mobile Number *</p>
                  <input
                    placeholder="0555 4444888"
                    className="py-2 px-2"
                    type="number"
                    name="mobile_number"
                    value={form.mobile_number}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="sell-car-inputs">
                  <p className="fw-bold">Email Address *</p>
                  <input
                    placeholder="Email"
                    className="py-2 px-2"
                    type="text"
                    name="email_address"
                    value={form.email_address}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <button type="submit" className="py-2 px-2 fw-light mb-4 sub-btn">
                SUBMIT
              </button>
            </form>
            <br />
          </div>
        </div>
        <animated.div ref={ref} style={fadeIn} className="container-xl">
         
          <div dangerouslySetInnerHTML={{ __html: htmlContent }}></div>
        </animated.div>

        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar
        />

        <div className="devider"></div>
        <Footer />
      </div>
    </>
  );
};

export default SellYourCar;

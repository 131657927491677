import Header from "../Comp/Header";
import "../Styles/Blog.css";
import { IoIosSearch } from "react-icons/io";
import { FaRegCalendarAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import Footer from "../Comp/Footer";
import { baseUrl } from "../config";
import { baseUrlForStorage } from "../config";

import React, { useEffect, useState } from "react";

const Blog = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [recentPosts, setRecentPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${baseUrl}blogs/search?title=${searchQuery}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setBlogPosts(data); // Assuming you have a state called blogPosts to store fetched blogs
    } catch (error) {
      console.error("Error fetching search results:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCategoryClick = async (categoryId) => {
    setIsLoading(true);
    setSelectedCategoryId(categoryId);

    try {
      const response = await fetch(`${baseUrl}blogs/category/${categoryId}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setBlogPosts(data); // Update the blog posts based on the selected category
    } catch (error) {
      console.error("Error fetching category posts:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetch(`${baseUrl}blog-posts`)
      .then((response) => response.json())
      .then((data) => setBlogPosts(data))
      .catch((error) => console.error("Failed to load blog posts", error))
      .finally(setIsLoading(false));
  }, []);

  useEffect(() => {
    fetchCategoriesWithCount();
  }, []);

  const fetchCategoriesWithCount = async () => {
    setIsLoading(true);

    try {
      const response = await fetch(`${baseUrl}getcategorieswithcount`);
      if (!response.ok) throw new Error("Failed to fetch categories");
      const data = await response.json();
      setCategories(data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchRecentPosts = async () => {
      setIsLoading(true);

      try {
        const response = await fetch(`${baseUrl}blogs/recent`);
        const data = await response.json();
        setRecentPosts(data);
      } catch (error) {
        console.error("Failed to fetch recent blog posts:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchRecentPosts();
  }, []);
  return (
    <>
      <div className="Blog-container">
        <div className="about-us-img">
          <img
            src="https://images.unsplash.com/photo-1486262715619-67b85e0b08d3?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt=""
            className="about-us-image"
          />
          <h1 className="display-2 about-us-title text-light fw-bold ">BLOG</h1>
          <div className="head-container">
            <div className="container-xxl pt-3">
              <Header />
            </div>
          </div>
        </div>
        <div className="container-xl">
          <div className="row justify-content-between">
            <div className="col-lg-8 col-12 py-5 ">
              <div className="blog-s-c">
                <div className="blog-serach-inp py-1 me-3  d-flex align-items-center justify-content-between px-2">
                  <input
                    type="text"
                    className="py-3"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onKeyDown={(e) => e.key === "Enter" && handleSearch()}
                  />
                  <IoIosSearch className="fs-3" onClick={handleSearch} />
                </div>
              </div>
              <div className="blog-grid mt-4 gap-2">
                {isLoading ? (
                  <div>Loading...</div> // Replace with a loading spinner if you have one
                ) : blogPosts.length === 0 ? (
                  <div>No Data Found</div>
                ) : (
                  blogPosts.map((post) => (
                    <div key={post.id} className="blog-grid-feature pb-4">
                      <div className="blog-grid-img">
                        <img
                          src={`${baseUrlForStorage}/${post.image}`}
                          alt={post.title}
                        />
                      </div>
                      <h2 className="fs-4 mt-3 ps-3">{post.title}</h2>
                      <div className="date d-flex align-items-center gap-2 ps-3">
                        <FaRegCalendarAlt />
                        <p className="m-0">
                          {new Date(post.created_at).toLocaleDateString()}
                        </p>
                      </div>
                      <p
                        className="px-3 mb-3 p-desc "
                        dangerouslySetInnerHTML={{ __html: post.description }}
                      />
                      <Link
                        to={`/SingleBlogPost/${post.id}`}
                        className="read-more-btn py-3 px-5 fw-bold mx-3 d-inline-block"
                      >
                        Read More
                      </Link>
                    </div>
                  ))
                )}
              </div>
            </div>
            <div className="col-lg-4 col-12 py-5">
              <h2 className="fs-4 mt-5">Categories</h2>
              <div className="cars-news py-2">
                {categories.map((category) => (
                  <div
                    key={category.id}
                    className="d-flex align-items-center justify-content-between px-3 py-2"
                    onClick={() => handleCategoryClick(category.id)}
                  >
                    <p
                      className="m-0"
                      style={{
                        cursor: "pointer",
                        color:
                          selectedCategoryId === category.id
                            ? "orange"
                            : "initial",
                      }}
                    >
                      {category.name}
                    </p>

                    <p className="m-0"> ({category.blog_posts_count}) </p>
                  </div>
                ))}
              </div>

              <h2 className="fs-4 mt-5 mb-4">Recent Posts</h2>

              {recentPosts.map((post) => (
                <div
                  key={post.id}
                  className="d-flex align-items-center gap-3 recent-posts-container mb-3"
                >
                  <div className="img-C">
                    <img
                      src={`${baseUrlForStorage}/${post.image}`}
                      alt={post.title}
                    />
                  </div>
                  <div>
                    <p className="fw-bold fs-5">{post.title}</p>
                    <Link
                      to={`/SingleBlogPost/${post.id}`}
                      className="mt-4 read-more-link"
                    >
                      Read more
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Blog;

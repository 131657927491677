import Header from "../Comp/Header";
import Search from "../Comp/Search";
import StockSection from "../Comp/StockSection";
import "../Styles/CarsForSale.css";
import Footer from "../Comp/Footer";

const CarsForSale = () => {
  return (
    <>
      <div className="cars-for-sale-container">
        <div className="head-container">
          <div className="about-us-img">
            <img
              src="https://images.unsplash.com/photo-1629820408206-e9fc918abf63?q=80&w=1386&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt=""
              className="about-us-image"
            />
            <h1 className="display-2 about-us-title text-light fw-bold ">
              Cars For Sale
            </h1>
            <div className="head-container">
              <div className="container-xxl pt-3">
                <Header />
              </div>
            </div>
          </div>
        </div>

        <Search />

        <Footer />
      </div>
    </>
  );
};

export default CarsForSale;

import { Link } from "react-router-dom";
import "../Styles/Header.css";
import logo from "../assests/logo.png";
import { FiMenu } from "react-icons/fi";
import { FiX } from "react-icons/fi";
import { useEffect, useState } from "react";
import { baseUrl } from '../config';
import { baseUrlForStorage } from "../config";

const Header = () => {
  const [show, setShow] = useState(true);
  const [showNav, setShowNav] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(`${baseUrl}first-sections/1`)
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((error) => console.error(error));
  }, []);
  return (
    <>
      <div className="header">
        <ul
          className={`d-flex flex-column ${
            showNav === true ? "active" : ""
          } ul-mobile py-4 d-lg-none`}
        >
          <Link to={"/"}>
            <li className="fw-semibold">Home</li>
          </Link>
          <Link to={"/carsforsale"}>
            <li className="fw-semibold">Cars For Sale</li>
          </Link>
          <Link to={"/SellYourCar"}>
            <li className="fw-semibold">Sell Your Car </li>
          </Link>
          <Link to={"/Services"}>
            <li className="fw-semibold">Services</li>
          </Link>
          <Link to={"/AboutUs"}>
            <li className="fw-semibold">About Us</li>
          </Link>
          <Link to={"/ContactUs"}>
            <li className="fw-semibold">Contact Us</li>
          </Link>
          <Link to={"/Blog"}>
            <li className="fw-semibold">Blog</li>
          </Link>
        </ul>

        <div className="container-xl header-container d-flex align-items-center justify-content-between">
          <div className="logo-container">
            <Link to={"/"}>
              <img
                className="header-logo "
                src={` ${
                  data ? `${baseUrlForStorage}/${data.logo}` : ""
                } `}
                alt=""
              />
            </Link>
          </div>
          {show === true ? (
            <FiMenu
              onClick={() => {
                setShow(false);
                setShowNav(true);
              }}
              className="header-humb-icon fs-1 d-lg-none"
            />
          ) : (
            <FiX
              className="header-humb-icon fs-1 d-lg-none"
              onClick={() => {
                setShow(true);
                setShowNav(false);
              }}
            />
          )}

          <ul className="d-flex align-items-center gap-3 mb-0 d-none d-lg-flex">
            <Link to={"/"}>
              <li className="fw-semibold">Home</li>
            </Link>
            <Link to={"/carsforsale"}>
              <li className="fw-semibold">Cars For Sale</li>
            </Link>
            <Link to={"/SellYourCar"}>
              <li className="fw-semibold">Sell Your Car </li>
            </Link>
            <Link to={"/Services"}>
              <li className="fw-semibold">Services</li>
            </Link>
            <Link to={"/AboutUs"}>
              <li className="fw-semibold">About Us</li>
            </Link>
            <Link to={"/ContactUs"}>
              <li className="fw-semibold">Contact Us</li>
            </Link>
            <Link to={"/Blog"}>
              <li className="fw-semibold">Blog</li>
            </Link>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Header;

import { useEffect, useState, useRef } from "react";
import "../Styles/OurPartners.css";
import { baseUrl,baseUrlForStorage } from "../config";

const OurPartners = () => {
  const [data, setData] = useState([]);
  const sliderRef = useRef(null);
  const isDragging = useRef(false);
  const startX = useRef(0);
  const scrollLeft = useRef(0);

  useEffect(() => {
    fetch(`${baseUrl}partners`)
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((error) => console.error(error));
  }, []);

  // Mouse events for drag functionality
  const onMouseDown = (e) => {
    isDragging.current = true;
    startX.current = e.pageX - sliderRef.current.offsetLeft;
    scrollLeft.current = sliderRef.current.scrollLeft;
  };

  const onMouseLeave = () => {
    isDragging.current = false;
  };

  const onMouseUp = () => {
    isDragging.current = false;
  };

  const onMouseMove = (e) => {
    if (!isDragging.current) return;
    e.preventDefault();
    const x = e.pageX - sliderRef.current.offsetLeft;
    const walk = (x - startX.current) * 2; // How far the mouse has moved
    sliderRef.current.scrollLeft = scrollLeft.current - walk;
  };

  return (
    <>
      <div className="partners-container">
        <div className="container-xl">
          <h6 className="fs-6 text-center">Our Partners</h6>
        </div>
        <div
          className="scroll text d-flex"
          ref={sliderRef}
          onMouseDown={onMouseDown}
          onMouseLeave={onMouseLeave}
          onMouseUp={onMouseUp}
          onMouseMove={onMouseMove}
        >
          <div className="d-flex">
            {data &&
              data.map((ele) => (
                <div key={ele.id} className="partner-logo">
                  <img
  src={`${baseUrlForStorage}/${ele.name}`} // Assuming the image URL is stored in ele.name
  alt={ele.name}
  className="partner-img"
/>

                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default OurPartners;

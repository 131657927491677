import React, { useEffect, useState } from 'react';

import Footer from "../Comp/Footer";
import Header from "../Comp/Header";
import "../Styles/AboutUs.css";
import { baseUrl } from '../config';
import { Editor } from '@tinymce/tinymce-react';






const AboutUs = () => {
  const [aboutUsContent, setAboutUsContent] = useState('');
  const [content, setContent] = useState('');
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    
    // Add event listener
    window.addEventListener('resize', handleResize);
    
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const aboutUsImgStyle = {
    height: screenWidth <= 768 ? '460px' : '300px',
  };
  useEffect(() => {


   

    const fetchAboutUsContent = async () => {
      try {
        const response = await fetch(`${baseUrl}about-us`);
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          const data = await response.json();
          if (data.content) {
            setAboutUsContent(data.content);
          }
        } else {
          console.log("Oops, we haven't got JSON!");
        }
      } catch (error) {
        console.error("Failed to fetch About Us content:", error);
      }
    };
    

    fetchAboutUsContent();
  }, []);


  const handleEditorChange = (content, editor) => {
    setContent(content);
  };

  return (
    <>
      <div className="About-us-container">
        <div className="about-us-img" style={aboutUsImgStyle}>
          <img
            src="https://images.unsplash.com/photo-1498887960847-2a5e46312788?q=80&w=1469&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt=""
            className="about-us-image"
          />
          <h1 className="display-2 about-us-title text-light fw-bold ">
            About Us
          </h1>
          <div className="head-container">
            <div className="container-xxl pt-3">
              <Header />
            </div>
          </div>
        </div>

       
       
        <div className="about-us-content body-content" dangerouslySetInnerHTML={{ __html: aboutUsContent }} />


        <Footer />


       

      </div>
    </>
  );
};

export default AboutUs;

import { Link } from "react-router-dom";
import "../Styles/Footer.css";
import { MdOutlinePhone } from "react-icons/md";
import { MdEmail } from "react-icons/md";
import { BiSolidReport } from "react-icons/bi";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa6";
import { useEffect, useState } from "react";
import { baseUrl } from '../config';
import { baseUrlForStorage } from "../config";

const Footer = () => {
  const [data, setData] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [companyDetails, setCompanyDetails] = useState(null);

  useEffect(() => {
    fetch(`${baseUrl}company-details/1`)
    .then((response) => response.json())
    .then((details) => setCompanyDetails(details))
    .catch((error) => console.error("Error fetching company details:", error));
    fetch(`${baseUrl}first-sections/1`)
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((error) => console.error(error));
      fetch(`${baseUrl}manufacturers`)
      .then((response) => response.json())
      .then((data) => setManufacturers(data))
      .catch((error) => console.error(error));
  }, []);
  return (
    <>
     <div className="container-xl foot footr-container">
        <div className="footer-container py-5">
          <div className="footer-contact">
            <h2 className="fs-5 fw-bold mb-3">CONTACT US</h2>
            <div className="d-flex align-items-center gap-2 mb-3">
              <MdOutlinePhone className="fs-6" />
              <p className="fs-6 m-0 p-0 infos">{companyDetails?.phone}</p>
            </div>
            <div className="d-flex align-items-center gap-2 mb-3">
              <MdEmail className="fs-6" />
              <p className="fs-6 m-0 p-0 infos">{companyDetails?.email}</p>
            </div>
            <div className="d-flex align-items-center gap-2 mb-3">
              <BiSolidReport className="fs-6" />
              <p className="fs-6 m-0 p-0 infos">{companyDetails?.our_opening_hours}</p>
            </div>
          </div>

          <div className="footer-Brands">
            <h2 className="fs-5 fw-bold mb-3">BRANDS WE OFFER</h2>
            <div>
              {manufacturers.map((manufacturer) => (
                <p key={manufacturer.id} className="fs-6 m-0 p-0">{manufacturer.name}</p>
              ))}
            </div>
          </div>

          <div className="footer-Location">
            <h2 className="fs-5 fw-bold mb-3">OUR LOCATION</h2>
            <p className="fs-6 m-0 p-0">{companyDetails?.our_location}</p>
          </div>

          <div className="footer-links pb-4 align-items-center justify-content-between">
            {/* Links and social media icons */}
          </div>

          <div className="d-flex align-items-center justify-content-between footer-desc ">
            {/* Logo and copyright text */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;

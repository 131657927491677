import { Divider } from "@mui/material";


const AchievementsNumber = ({ data }) => {
    return (
      <div className="achivments-number flex-wrap d-flex align-items-center mt-5">
        <div
          data-aos="zoom-in-up"
          data-aos-delay="100"
          className="fw-bold d-flex align-items-center flex-column justify-content-center fs-2"
        >
          <h2 id="value1" className="fs-1 fw-bold">
            0
          </h2>
          <p className="text-center">{data.value_1_text}</p>
        </div>
        <div
          data-aos="zoom-in-up"
          data-aos-delay="200"
          className="fw-bold d-flex align-items-center flex-column justify-content-center fs-2"
        >
          <h2 id="value2" className="fs-1 fw-bold">
            0
          </h2>
          <p className="text-center">{data.value_2_text}</p>
        </div>
        <div
          data-aos="zoom-in-up"
          data-aos-delay="300"
          className="fw-bold d-flex align-items-center flex-column justify-content-center fs-2"
        >
          <h2 id="value3" className="fs-1 fw-bold">
            0
          </h2>
          <p className="text-center number-value">{data.value_3_text}</p>
        </div>
             </div>
    );
  };
  
  export default AchievementsNumber;
  
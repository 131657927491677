import { Typography } from "@mui/material";

const Achievements = ({ data }) => {
  return (
    <div className="achivments">
      <Typography
        variant="h3"
        align="center"
        sx={{ fontWeight: "bold", wordWrap: "break-word" }}
      >
       About Us
      </Typography>

      <div className="our-vision my-3" data-aos="fade-right" data-aos-delay="100">
        <h5 className="fw-bold">{data.secondary_title_1}</h5>
        <ul>
          <li className="fw-bold text-wrap"> {data.body_1}</li>
        </ul>
      </div>
      <div className="our-vision my-3" data-aos="fade-right" data-aos-delay="200">
        <h5 className="fw-bold">{data.secondary_title_2}</h5>
        <ul>
          <li className="fw-bold text-wrap"> {data.body_2}</li>
        </ul>
      </div>
      <div className="our-vision my-3" data-aos="fade-right" data-aos-delay="300">
        <h5 className="fw-bold">{data.secondary_title_3}</h5>
        <ul>
          <li className="fw-bold text-wrap"> {data.body_3}</li>
        </ul>
      </div>
    </div>
  );
};

export default Achievements;

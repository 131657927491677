import "../Styles/BlogDetails.css";
import AOS from "aos";
import { useEffect, useState } from "react";
import { baseUrl, baseUrlForStorage } from "../config";

AOS.init({
  once: true,
});

const BlogDetails = () => {
  const [data, setData] = useState([]); // State for text data
  const [imagePath, setImagePath] = useState(null); // State for the image path

  useEffect(() => {
    // Fetch text data
    fetch(`${baseUrl}second-sections/1`)
      .then((response) => response.json())
      .then((data) => {
        setData(data);
      })
      .catch((error) => console.error(error));

      fetch(`${baseUrl}image-section/latest-image`)
    .then((response) => response.json())
    .then((imageData) => {
      setImagePath(imageData.image_path); // Save the image path
    })
    .catch((error) => console.error(error));
}, []);

  const highlightFirstLetter = (text) => {
    return text.split(" ").map((word, index) => (
      <span key={index} className="title-word">
        <span className="first-letter">{word.charAt(0)}</span>
        {word.slice(1)}&nbsp;
      </span>
    ));
  };

  return (
    <>
      <div className="blog-details py-5">
        <div className="container-xl">
          <div className="blog-details-container">
            {imagePath ? (
              <img
                src={`${imagePath}`} 
                alt="Main Title Image"
                className="main-image"
                style={{ width: "10%", height: "auto" }} 
                data-aos="fade-up"
              />
            ) : (
              <p>Loading image...</p>
            )}

            <div className="blog-details-features d-flex align-items-start justify-content-start gap-5 mt-3">
              {data && data.details && data.details.length > 0 ? (
                data.details.map((ele, index) => (
                  <div
                    className="d-flex align-items-start"
                    key={ele.id}
                    style={{ position: "relative" }}
                  >
                   
                    {index > 0 && (
                      <div
                        style={{
                          position: "absolute",
                          left: "-20px", // Adjust spacing here
                          top: "0",
                          bottom: "0",
                          width: "2px",
                          height: "16rem",
                          backgroundColor: "#bfbdbd", // Color of divider
                        }}
                      ></div>
                    )}

                    <div
                      className="feature text-start"
                      data-aos="fade-right"
                      data-aos-delay="100"
                    >
                      <h1 className="display-6 fw-bold title-color title-limited">
                        {highlightFirstLetter(ele.title)}
                      </h1>
                      <p>{ele.body}</p>
                    </div>
                  </div>
                ))
              ) : (
                <p>No blog details available.</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogDetails;

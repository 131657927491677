import { useEffect, useState, useRef } from "react";
import Header from "../Comp/Header";
import "../Styles/CarDetails.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/swiper-bundle.css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { FiCalendar } from "react-icons/fi";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import PhoneInput from "react-phone-number-input";
import { FiUser  } from "react-icons/fi";
import { MdOutlineEmail } from "react-icons/md";
import { FiPhone } from "react-icons/fi";
import { FiX } from "react-icons/fi"; // Import the close icon
import { Link } from "react-router-dom";
import { IoSpeedometerOutline } from "react-icons/io5";
import { PiGasCan } from "react-icons/pi";
import { TbManualGearbox } from "react-icons/tb";
import { useParams } from "react-router-dom";
import Footer from "../Comp/Footer";
import Lottie from "react-lottie";
import loadingAnimation from "../assests/car-loading.json";
import { InstagramEmbed } from "react-social-media-embed";
import { baseUrl } from "../config";
import { baseUrlForStorage } from "../config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Slider from "react-slick";
import { CiGlobe } from "react-icons/ci";
import logo from "../assests/logo.png";

const CarDetails = () => {
  const [show, setShow] = useState(false);
  const mapStyles = {
    height: "100%",
    width: "100%",
  };

  const { id } = useParams();
  const [carDetails, setCarDetails] = useState(null);
  const [description, setDescription] = useState(null);

  const [relatedListings, setRelatedListings] = useState([]);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [currency, setCurrency] = useState("AED");
  const aedToUsdRate = 0.27;
  const [showContactOptions, setShowContactOptions] = useState(false);
  const [currentPhoneNumber, setCurrentPhoneNumber] = useState("");
  const [visibleContactOptions, setVisibleContactOptions] = useState([]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };


  const defaultCenter = carDetails
    ? {
        lat: parseFloat(25.1714989), // Make sure to parse the latitude as a float
        lng: parseFloat(55.3252983), // Make sure to parse the longitude as a float
      }
    : {
        lat: 40.712776,
        lng: -74.005974,
      };
  const openContactOptions = (id) => {
    // If already visible, hide it, otherwise show it
    setVisibleContactOptions((prev) =>
      prev.includes(id) ? prev.filter((pid) => pid !== id) : [...prev, id]
    );
  };

  useEffect(() => {
    const handleScroll = () => {
      setShowContactOptions(false);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleCall = () => {
    window.location.href = `tel:${carDetails.owner["phone_number"]}`;
  };

  const handleCallForGlobalOwner = () => {
    window.location.href = `tel:${carDetails.globalOwner["phone"]}`;
  };

  useEffect(() => {
    // Fetch car details when the component mounts or the id changes
    const fetchCarDetails = async () => {
      try {
        const response = await fetch(`${baseUrl}car-listings/${id}`);
        if (!response.ok) {
          throw new Error("Failed to fetch car details");
        }
        const data = await response.json();
        setCarDetails(data);
      } catch (error) {
        console.error("Error fetching car details:", error);
      }
    };

    const fetchDescription = async () => {
      try {
        const response = await fetch(`${baseUrl}company-details/1`);
        if (!response.ok) {
          throw new Error("Failed to fetch car details");
        }
        const data = await response.json();
        setDescription(data);
      } catch (error) {
        console.error("Error fetching car details:", error);
      }
    };

    fetchDescription();
    fetchCarDetails();
  }, [id]);

  let pricePerMonthDisplay;
  if (carDetails && carDetails.price_per_month) {
    const pricePerMonth = parseFloat(carDetails.price_per_month);
    if (currency === "USD") {
      pricePerMonthDisplay = `$${(pricePerMonth * aedToUsdRate).toFixed(
        2
      )} / Month`;
    } else {
      pricePerMonthDisplay = `AED ${pricePerMonth.toFixed(2)} / Month`;
    }
  }

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  const validateForm = () => {
    let tempErrors = { ...errors };
    let isValid = true;

    if (!name.trim()) {
      tempErrors.name = "Name is required";
      isValid = false;
    } else {
      tempErrors.name = "";
    }

    // Email validation
    if (!email.trim()) {
      tempErrors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      tempErrors.email = "Email is invalid";
      isValid = false;
    } else {
      tempErrors.email = "";
    }

    // Phone Number validation
    // Assuming you're using a library like 'react-phone-number-input' which validates the number internally
    if (!phoneNumber) {
      tempErrors.phoneNumber = "Phone number is required";
      isValid = false;
    } else {
      tempErrors.phoneNumber = "";
    }

    // Message validation
    if (!message.trim()) {
      tempErrors.message = "Message is required";
      isValid = false;
    } else {
      tempErrors.message = "";
    }

    setErrors({ ...tempErrors });
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      name,
      email,
      phone_number: phoneNumber,
      message,
      owner_id: carDetails.owner["id"],
      car_listing_id: id,
    };

    try {
      const response = await fetch(`${baseUrl}send-message`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errorDetails = await response.json();
        console.error("Failed to submit:", errorDetails);

        return;
      }

      const result = await response.json();

      toast.success("Data sent successfully!", {
        position: "bottom-center",
      });
      console.log("Success:", result);
      // Process success response, such as displaying a success message or resetting the form
      setName("");
      setEmail("");
      setPhoneNumber("");
      setMessage("");
      // Optionally, show a success message or redirect the user
    } catch (error) {
      console.error("Error:", error);
    }

    if (!validateForm()) {
      console.error("Validation failed.");
      return;
    }
  };

  useEffect(() => {
    const fetchRelatedListings = async () => {
      try {
        const response = await fetch(`${baseUrl}car-listings/${id}/similar`);
        if (!response.ok) throw new Error("Network response was not ok");
        const data = await response.json();
        setRelatedListings(data);
      } catch (error) {
        console.error("Could not fetch related listings:", error);
      }
    };

    fetchRelatedListings();
  }, []);

  const closeContactOptions = (id) => {
    setVisibleContactOptions((prev) => prev.filter((pid) => pid !== id));
  };

  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);
  useEffect(() => {
    if (sliderRef1.current && sliderRef2.current) {
      setNav1(sliderRef1.current);
      setNav2(sliderRef2.current);
    }
  }, [sliderRef1, sliderRef2]);
  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  if (!carDetails) {
    return (
      <div
        style={{
          position: "fixed", // Use fixed or absolute, depending on need
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100vw",
          height: "100vh",
        }}
      >
        <img src={logo} alt="Loading" style={{ width: 200, height: 200,objectFit :"cover" }} />
      </div>
    );
  }
  

  return (
    <>
      <div className="carDetails-container">
        <div className="about-us-img">
          <img
            src={`${baseUrlForStorage}/${carDetails.image} `}
            alt=""
            className="about-us-image"
          />
          <h1 className="display-2 about-us-title text-light fw-bold ">
            {carDetails.title}
          </h1>
          <div className="head-container">
            <div className="container-xxl pt-3">
              <Header />
            </div>
          </div>
        </div>
        <div className="container-xxl mt-5">
          <div className="car-grid-container d-flex justify-content-center">
            <div className="row">
              <div className="car-info col-xl-7 col-12">
                <div className="slider-container">
                  <Slider
                    {...settings}
                    asNavFor={nav2}
                    ref={(slider) => setNav1(slider)}
                  >
                    {carDetails &&
                      carDetails.images.map((image, index) => (
                        <div key={index} className="slide">
                          <img
                            src={`${baseUrlForStorage}/${image.image_path}`}
                            alt={`Slide ${index}`}
                          />
                        </div>
                      ))}
                  </Slider>

                  {/* Thumbnails Slider */}
                  <Slider
                    asNavFor={nav1}
                    ref={(slider) => setNav2(slider)}
                    slidesToShow={3}
                    swipeToSlide={true}
                    focusOnSelect={true}
                  >
                    {carDetails &&
                      carDetails.images.map((image, index) => (
                        <div key={index} className="down-slide">
                          <img
                            src={`${baseUrlForStorage}/${image.image_path}`}
                            alt={`Thumbnail ${index}`}
                          />
                        </div>
                      ))}
                  </Slider>
                </div>
                <div className="wrapper-mobile">
                  <h1 className="display-6">{carDetails?.title}</h1>
                  <ul className="d-flex align-items-center gap-4 p-0 seller-info-ul pb-3">
                    <li className="li-first"> {carDetails?.year}</li>
                    <li>{carDetails?.mileage} Km</li>
                    <li>{carDetails?.body_type["type"]}</li>
                    <li>{carDetails?.fuel_type}</li>
                  </ul>

                  <div
                    className="price-currency-container"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <h3 className="fs-6 fw-bold pe-2 pt-2 car-price">
                      {currency === "AED" ? (
                        <span className="display-6 fw-bold side-car-price">
                          AED {carDetails.price}
                        </span>
                      ) : (
                        <span className="display-6 fw-bold side-car-price">
                          USD {(carDetails.price * aedToUsdRate).toFixed(2)}
                        </span>
                      )}
                    </h3>

                    <select
                      value={currency}
                      onChange={(e) => setCurrency(e.target.value)}
                      className="currency-selector"
                      style={{ marginRight: "10px" }}
                    >
                      <option value="AED">AED</option>
                      <option value="USD">USD</option>
                    </select>
                  </div>
                  {pricePerMonthDisplay && <h6>{pricePerMonthDisplay}</h6>}

                  <div className="car-informations d-flex align-items-center justify-content-center ">
                    <div className="">
                      <p className="fw-bold">Make:</p>
                      <p className="fw-bold">Model:</p>
                      <p className="fw-bold">Year:</p>
                      <p className="fw-bold">Mileage:</p>
                      <p className="fw-bold">Engine Size:</p>
                      <p className="fw-bold">Regional Spec:</p>
                      <p className="fw-bold">Warranty:</p>
                      <p className="fw-bold">Service Contract:</p>
                    </div>

                    <div className="">
                      <p className="fw-bold">
                        {carDetails?.car_model.manufacturer["name"]}
                      </p>
                      <p className="fw-bold">
                        {" "}
                        {carDetails?.car_model["name"]}
                      </p>
                      <p className="fw-bold">{carDetails.year}</p>
                      <p className="fw-bold">{carDetails.mileage}</p>
                      <p className="fw-bold">{carDetails.engine_size}</p>
                      <p className="fw-bold">{carDetails.regional_spec}</p>
                      <p className="fw-bold">{carDetails?.warranty}</p>
                      <p className="fw-bold">{carDetails?.service_contact}</p>
                    </div>
                  </div>
                  <div className="side-contact px-2 py-3 mt-4">
                    <h3 className="fs-4">Contact</h3>
                    <div className="d-flex align-items-center gap-3  ">
                      <FiUser  className="fs-5" />
                      <span>{carDetails?.owner["name"]}</span>
                    </div>
                    <div className="d-flex align-items-center gap-3  pt-3">
                      <MdOutlineEmail className="fs-5" />
                      <span>{carDetails?.owner["email"]}</span>
                    </div>
                    <button className="mt-3 py-3 px-2 d-flex align-items-center justify-content-center gap-3 telephone">
                      <FiPhone />
                      <h6 className="m-0">
                        {carDetails.owner["phone_number"]}
                      </h6>
                    </button>
                    {/* WhatsApp Button Styled Like Previous Button */}
                    <a
                      href={`https://wa.me/${carDetails.owner["phone_number"]
                        .replace(/[^0-9]/g, "")
                        .replace(
                          /^00/,
                          "+"
                        )}?text=I'm%20interested%20in%20your%20car%20for%20sale`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="mt-3 py-3 px-2 d-flex align-items-center justify-content-center gap-3 telephone"
                      style={{
                        backgroundColor: "#25D366",
                        color: "white",
                        textDecoration: "none",
                        borderRadius: "5px",
                        width: "100%",
                        textAlign: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h6 className="m-0 contact-on-whats">
                        Contact on WhatsApp
                      </h6>
                    </a>
                  </div>
                </div>

                <div className="description-container">
                  <h3 className="fw-bold mb-4 mt-5">Description</h3>
                  <p className="m-2" dangerouslySetInnerHTML={{ __html: carDetails?.description }}></p>

                  <br />
                  <span className="fw-bold">
                    {description?.post_description_title}
                  </span>
                  {show ? (
                    <p>{description?.post_description}</p>
                  ) : (
                    <p>
                      {description?.post_description.slice(0, 200)}...{" "}
                      <span
                        onClick={() => setShow(true)}
                        className="show-hide-btn"
                      >
                        Show More
                      </span>
                    </p>
                  )}
                  {show && (
                    <span
                      onClick={() => setShow(false)}
                      className="show-hide-btn"
                    >
                      Show Less
                    </span>
                  )}
                </div>

                <div className="video mb-4 mt-3">
                  <h3 className="fw-bold mb-4 mt-5">Instagram Feed</h3>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "10px",
                    }}
                  >
                    <InstagramEmbed
                      url={carDetails.instagram_post}
                      width={380}
                      height={700}
                    />
                  </div>
                </div>

                <div className="features mt-5 py-4"></div>
                <div className="location mb-5">
                  <h3 className="fw-bold mb-4 mt-3">Location</h3>
                  <LoadScript googleMapsApiKey="" className="map">
                    <GoogleMap
                      mapContainerStyle={mapStyles}
                      zoom={13}
                      center={defaultCenter}
                    >
                      <Marker position={defaultCenter} />
                    </GoogleMap>
                  </LoadScript>
                </div>
              </div>
              <div className="seller-info col-xl-5 d-xl-block d-none">
                <div className="wrapper">
                  <h1 className="display-6">{carDetails.title}</h1>
                  <ul className="d-flex align-items-center gap-4 p-0 seller-info-ul pb-3">
                    <li className="li-first"> {carDetails.year}</li>
                    <li> {carDetails.mileage} Km</li>
                    <li>{carDetails.body_type["type"]}</li>
                    <li>{carDetails.fuel_type}</li>
                  </ul>

                  <div
                    className="price-currency-container"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <h3 className="fs-6 fw-bold pe-2 pt-2 car-price">
                      {currency === "AED" ? (
                        <span className="display-6 fw-bold side-car-price">
                          AED {carDetails.price}
                        </span>
                      ) : (
                        <span className="display-6 fw-bold side-car-price">
                          USD {(carDetails.price * aedToUsdRate).toFixed(2)}
                        </span>
                      )}
                    </h3>

                    <select
                      value={currency}
                      onChange={(e) => setCurrency(e.target.value)}
                      className="currency-selector"
                    >
                      <option value="AED">AED</option>
                      <option value="USD">USD</option>
                    </select>
                  </div>
                  {pricePerMonthDisplay && <h6>{pricePerMonthDisplay}</h6>}

                  <div className="car-informations d-flex align-items-center justify-content-center px-5 py-3">
                    <div className="">
                      <p className="fw-bold">Make:</p>
                      <p className="fw-bold">Model:</p>
                      <p className="fw-bold">Year:</p>
                      <p className="fw-bold">Mileage:</p>
                      <p className="fw-bold">Engine Size:</p>
                      <p className="fw-bold">Regional Spec:</p>
                      <p className="fw-bold">Warranty:</p>
                      <p className="fw-bold">Service Contact:</p>
                    </div>

                    <div className="">
                      <p className="fw-bold">
                        {carDetails?.car_model.manufacturer["name"]}
                      </p>
                      <p className="fw-bold">
                        {" "}
                        {carDetails?.car_model["name"]}
                      </p>
                      <p className="fw-bold">{carDetails?.year}</p>
                      <p className="fw-bold">{carDetails?.mileage}</p>
                      <p className="fw-bold">{carDetails?.engine_size}</p>
                      <p className="fw-bold">{carDetails?.regional_spec}</p>
                      <p className="fw-bold">{carDetails?.warranty}</p>
                      <p className="fw-bold">{carDetails?.service_contact}</p>
                    </div>
                  </div>
                  <div className="sticky-container">
                    <h3 className="fs-4 mb-2">Contact</h3>

                    <div className="d-flex align-items-center gap-3  ">
                      <FiUser  className="fs-5" />
                      <span>{carDetails?.owner["name"]}</span>
                    </div>
                    <div className="d-flex align-items-center gap-3  pt-3">
                      <MdOutlineEmail className="fs-5" />
                      <span>{carDetails?.owner["email"]}</span>
                    </div>
                    <button
                      className="mt-3 py-3 px-2 d-flex align-items-center justify-content-center gap-3 telephone"
                      onClick={handleCall}
                    >
                      <FiPhone />
                      <h6 className="m-0">
                        {carDetails.owner["phone_number"].replace(/^00/, "+")}
                      </h6>
                    </button>
                    {/* WhatsApp Button Styled Like Previous Button */}
                    <a
                      href={`https://wa.me/${carDetails.owner["phone_number"]
                        .replace(/^00/, "+")
                        .replace(/[^0-9]/g, "")}?text=I'm%20interested%20in%20your%20car%20for%20sale`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="mt-3 py-3 px-2 d-flex align-items-center justify-content-center gap-3 telephone"
                      style={{
                        backgroundColor: "#25D366",
                        color: "white",
                        textDecoration: "none",
                        borderRadius: "5px",
                        width: "100%",
                        textAlign: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h6 className="m-0 contact-on-whats">
                        Contact on WhatsApp
                      </h6>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="send-message-container py-3 mt-5">
          <div className="container-xxl">
            <h3 className="fw-bold mb-4 mt-3">Send message</h3>
            <div className="row contact-info-container">
              <div className="col-xl-7 col-12">
                <form className="mb-2" onSubmit={handleSubmit}>
                  <div className="d-flex align-items-center justify-content-between flex-wrap">
                    <input
                      type="text"
                      placeholder="Name*"
                      className="px-2 py-2"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    {errors.name && <div className="error">{errors.name}</div>}

                    <input
                      type="email"
                      placeholder="Email*"
                      className="px-2 py-2"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {errors.email && (
                      <div className="error">{errors.email}</div>
                    )}

                    <PhoneInput
                      placeholder="Enter phone number"
                      value={phoneNumber}
                      onChange={setPhoneNumber}
                      className="phone"
                    />
                    {errors.phoneNumber && (
                      <div className="error">{errors.phoneNumber}</div>
                    )}
                  </div>
                  <textarea
                    className="mt-3 px-2 py-2"
                    cols="30"
                    rows="10"
                    placeholder="Message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                  {errors.message && (
                    <div className="error">{errors.message}</div>
                  )}

                  <button type="submit" className="button py-2 px-2 mt-2">
                    Submit
                  </button>
                </form>
              </div>
              <div className="col-xl-5 col-12">
                <div className="contact-info">
                  <h5 className="fw-bold ps-4 pt-4">Send message</h5>
                  <div className="d-flex align-items-center gap-3 ps-4 pt-3">
                    <FiUser  className="fs-5" />
                   

                    <span>{carDetails.globalOwner["name"]}</span>
                    </div>
                  <div className="d-flex align-items-center gap-3 ps-4 pt-3">
                    <MdOutlineEmail className="fs-5" />
                   

                    <span>{carDetails.globalOwner["email"]}</span>
                  </div>
                  <button
                    className="mt-3 py-3 px-2 d-flex align-items-center justify-content-center gap-3 telephone"
                    onClick={handleCallForGlobalOwner}
                  >
                    <FiPhone />
                    <h6 className="m-0">{carDetails.globalOwner["phone"]}</h6>
                  </button>
                  {/* WhatsApp Button Styled Like Previous Button */}
                  <a
                    href={`https://wa.me/${carDetails.globalOwner[
                      "phone"
                    ].replace(
                      /[^0-9]/g,
                      ""
                    )}?text=I'm%20interested%20in%20your%20car%20for%20sale`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mt-3 py-3 px-2 d-flex align-items-center justify-content-center gap-3 telephone"
                    style={{
                      backgroundColor: "#25D366",
                      color: "white",
                      textDecoration: "none",
                      borderRadius: "5px",
                      width: "100%",
                      textAlign: "center",
                      justifyContent: "center",
                    }}
                  >
                    <h6 className="m-0 contact-on-whats">
                      Contact on WhatsApp
                    </h6>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="related-listings-container pt-5">
          <div className="container-xxl">
            <h3 className="fw-bold mb-4 ">Related listings</h3>
            <Swiper
              slidesPerView={4}
              spaceBetween={30}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                380: {
                  slidesPerView: 1,
                },
                600: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 10,
                },
              }}
              className="mySwiper "
              navigation={true}
              modules={[Navigation]}
            >
              {relatedListings.map((listing) => (
                <SwiperSlide key={listing.id}>
                  <div className="new-arrival-feature pb-1">
                    <div className="new-arrival-feature-img">
                      <img src={`${baseUrlForStorage}/${listing.image}`} />
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <h3 className="fs-6 fw-bold ps-2 pt-2 car-name">
                        {listing.title}
                      </h3>

                      <div
                        className="price-currency-container"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <h3 className="fs-6 fw-bold pe-2 pt-2 car-price">
                          {currency === "AED" ? (
                            <span>AED {listing.price}</span>
                          ) : (
                            <span>
                              USD {(listing.price * aedToUsdRate).toFixed(2)}
                            </span>
                          )}
                        </h3>
                      </div>
                    </div>

                    <div className="new-arrival-infos d-flex align-items-center justify-content-between  mx-2 ">
                      <div className="info d-flex align-items-center justify-content-center gap-1 ">
                        <FiCalendar />
                        <p className="m-0">{listing.year}</p>
                      </div>
                      <div className="info d-flex align-items-center justify-content-center gap-1">
                        <CiGlobe />
                        <p className="m-0">{listing.regional_spec}</p>
                      </div>
                      <div className="info d-flex align-items-center justify-content-center gap-1 ">
                        <PiGasCan />

                        <p className="m-0">{listing.fuel_type}</p>
                      </div>
                      <div className="info d-flex align-items-center justify-content-center gap-1 ">
                        <IoSpeedometerOutline />
                        <p className="m-0">{listing.mileage}</p>
                      </div>
                    </div>
                    <div className="new-arrival-btns d-flex align-items-center justify-content-center gap-2 mx-2 my-2">
                      <button
                        className="py-2"
                        onClick={() => openContactOptions(listing.id)}
                      >
                        <span> I’m Interested</span>
                      </button>

                      {visibleContactOptions.includes(listing.id) && (
                        <div className="contact-options-container">
                          <button
                            className="close-contact-options"
                            onClick={() => closeContactOptions(listing.id)}
                            aria-label="Close"
                          >
                            <FiX size={24} />
                          </button>
                          <br />
                          <div className="contact-info">
                            <h3 className="contact-title">
                              {listing.car_model.manufacturer["name"]}
                            </h3>
                            <h3 className="contact-title">
                              {listing.car_model["name"]}
                            </h3>

                            <p className="contact-name">
                              {listing.owner["name"]}
                            </p>
                            <p className="operational-hours">Seller</p>
                          </div>
                          <a
                            href={`tel:${currentPhoneNumber}`}
                            className="contact-option phone-call-button"
                          >
                            <span className="button-text-call">Call Now</span>
                          </a>
                          <a
                            href={`https://wa.me/${currentPhoneNumber}?text=Hello, I'm interested in your car. Car Title: ${
                              listing.title +
                              " Year: " +
                              listing.year +
                              " Price " +
                              listing.price
                            } `}
                            className="contact-option whatsapp-button"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span className="button-text-call">
                              Message on WhatsApp
                            </span>
                          </a>
                        </div>
                      )}
                      <Link
                        className="view-button py-2"
                        to={`/car-details/${listing.id}`}
                        onClick={scrollToTop}
                      >
                        View
                      </Link>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
      <Footer />
    </>
  );
};

export default CarDetails;

import { useEffect, useState } from "react";
import "../Styles/Achievements.css";
import { CountUp } from "countup.js";
import { baseUrl } from "../config";
import Achievements from "../Comp/Achievements/Achievements";
import AchievementsNumber from "../Comp/Achievements/AchievementsNumber";

const OurAchievements = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(`${baseUrl}complex-structures/1`)
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    const animateValues = () => {
      const value1 = new CountUp("value1", data.value_1);
      const value2 = new CountUp("value2", data.value_2);
      const value3 = new CountUp("value3", data.value_3);

      if (!value1.error && !value2.error && !value3.error) {
        value1.start();
        value2.start();
        value3.start();
      } else {
        console.error("CountUp error");
      }
    };

    if (data) {
      animateValues();
    }
  }, [data]);

  return (
    <div className="achievements-container py-5">
      {/* Custom div acting as a horizontal divider */}
      <div
        style={{
          backgroundColor: "#a3a2a2",
          height: "2px",
          width: "90%",
          margin: "0 auto 28px", // Aligns it to the center and adds margin at the bottom
        }}
      />

      <div className="container-xl d-flex">
        <div className="achivments-container">
          {/* Achievements Component */}
          <Achievements data={data} />
        </div>
      </div>

      <div
        style={{
          backgroundColor: "grey",
          height: "10px",
          width: "100%",
          marginTop: "28px", // Equivalent to mt: 7 in MUI
          boxShadow: "inset 0px 0px 10px rgba(0, 0, 0, 0.8)", // Inner shadow
        }}
      />

      <div className="container-xl d-flex">
        <div className="achivments-container">
          {/* Achievements Number Component */}
          <AchievementsNumber data={data} />
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#a3a2a2",
          height: "2px",
          width: "90%",
          margin: "2.5rem auto 28px", // Aligns it to the center and adds margin at the bottom
        }}
      />
     
    </div>
  );
};

export default OurAchievements;

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "../Styles/HomeSlider.css";
import React, { useEffect, useState } from "react";
import { Pagination, Autoplay } from "swiper/modules";
import gsap from "gsap";
import { baseUrl, baseUrlForStorage } from "../config";
import logo from "../assests/logo.png"; // Make sure the path to your logo is correct

const HomeSlider = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true); // State to manage loading

  useEffect(() => {
    fetch(`${baseUrl}first-sections/1`)
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        setLoading(false); // Set loading to false once data is fetched
      })
      .catch((error) => {
        console.error(error);
        setLoading(false); // Set loading to false in case of error
      });

    let swipTitle = document.querySelector(".swip-title");

    gsap.fromTo(
      swipTitle,
      { x: 0, opacity: 0 },
      { x: 100, duration: 1.5, ease: "back.inOut", opacity: 1 }
    );
  }, []);

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <img
            src={logo}
            alt="Loading..."
            style={{
              width: "150px",
              height: "150px",
              animation: "fadeIn 1s ease-in-out",
              objectFit :"cover",
            }}
          />
        </div>
      ) : (
        <Swiper
          pagination={{
            clickable: true,
          }}
          modules={[Pagination, Autoplay]}
          className="mySwiper Swpier-C"
        >
          {data &&
            data.images &&
            data.images.map((ele) => {
              return (
                <SwiperSlide className="swip" key={ele.id}>
                  <div className="container-xl">
                    <h1 className="display-4 fw-bold text-wrap swip-title">
                      <img
                        className="swip-img"
                        src={`${baseUrlForStorage}/${ele.image_path}`}
                        alt=""
                      />
                    </h1>
                  </div>
                </SwiperSlide>
              );
            })}
        </Swiper>
      )}
    </>
  );
};

export default HomeSlider;
